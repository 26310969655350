import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduce, includes } from 'lodash';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { Center, Container, StyledButton } from './project-filter.css';

const ProjectFilter = ({ items, changeFilterProjects, filterProjects }) => {
  const options = reduce(
    items,
    function(carry, { node }) {
      node.category &&
        node.category.map(t => {
          !includes(carry, t) && carry.push(t);
        });
      return carry;
    },
    []
  )
  const finalOptions = ['All Services', ...options]
  let defaultOption = finalOptions[0];
  if (typeof window !== "undefined") {
    const querystring = window.location.search
    const params = new URLSearchParams(querystring)
    const serviceFromParams = params.get("service")
    if (finalOptions.includes(serviceFromParams)) {
      defaultOption = serviceFromParams
      // changeFilterProjects(serviceFromParams);
    }
  }

  useEffect(() => {
    if (defaultOption === null || defaultOption === 'All Services') {
      changeFilterProjects(null);
    } else {
      changeFilterProjects(defaultOption);
    }
  }, [changeFilterProjects, defaultOption]);

  const onChange = ({value}) => {
    if (value === null || value === 'All Services') {
      changeFilterProjects(null);
    } else {
      changeFilterProjects(value);
    }
  }

  return (
    <Center>
      <Container>
        <Dropdown
          className="!shadow-none !border-none"
          controlClassName=""
          menuClassName="!shadow-none !border-none"
          options={finalOptions}
          onChange={onChange}
          value={defaultOption}
          placeholder="Select an option"
        />
        {/* <StyledButton
          key={'all'}
          onClick={e => {
            e.preventDefault();
            changeFilterProjects(null);
          }}
          active={
            filterProjects === null
              ? 'text-brand-green border-b-2 border-solid border-brand-green'
              : 'border-b-2 border-solid border-transparent'
          }
        >
          All
        </StyledButton>
        {reduce(
          items,
          function(carry, { node }) {
            node.category &&
              node.category.map(t => {
                !includes(carry, t) && carry.push(t);
              });
            return carry;
          },
          []
        ).map(category => (
          <StyledButton
            key={category}
            onClick={e => {
              e.preventDefault();
              changeFilterProjects(category);
            }}
            active={
              filterProjects === category
                ? 'text-brand-green border-b-2 border-solid border-brand-green'
                : 'border-b-2 border-solid border-transparent'
            }
          >
            {category}
          </StyledButton>
        ))} */}
      </Container>
    </Center>
  );
};

ProjectFilter.propTypes = {
  items: PropTypes.array.isRequired,
  filterJournal: PropTypes.string,
  changeFilterJournal: PropTypes.func.isRequired,
  searchJournal: PropTypes.string,
  changeSearchJournal: PropTypes.func.isRequired,
};

export default ProjectFilter;
