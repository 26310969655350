import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter, includes, isEqual, sortBy } from 'lodash';

import { Center, Container } from './project-list.css';
import ProjectPost from './project-post';

const ProjectList = ({ items, filterProjects }) => {
  const filteredItems = filter(items, ({ node }) => {
    if (filterProjects == null) {
      return true;
    }
    return includes(node.category, filterProjects);
  });

  const [hasMore, setMore] = useState(filteredItems.length > 10);
  const [currentList, addToList] = useState([...filteredItems.slice(0, 10)]);

  const loadItems = () => {
    const currentLength = currentList.length;
    const more = currentLength < filteredItems.length;
    const nextItems = more
      ? filteredItems.slice(currentLength, currentLength + 20)
      : [];
    setMore(more);
    addToList([...currentList, ...nextItems]);
  };

  const handleScroll = () => {
    if (!hasMore) return;
    if (
      window &&
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - window.innerHeight
    ) {
      loadItems();
    }
  };

  // const handleTouchEnd = e => {
  //   e.preventDefault();
  //   handleScroll();
  // };

  useEffect(() => {
    if (
      !isEqual(
        sortBy(currentList),
        sortBy(filteredItems.slice(0, currentList.length))
      ) ||
      currentList.length === 0
    ) {
      addToList([...filteredItems.slice(0, 10)]);
      const more = currentList.length < filteredItems.length;
      setMore(more);
    }
  }, [filterProjects]);

  useEffect(() => {
    window && window.addEventListener('scroll', handleScroll);
    window && window.addEventListener('resize', handleScroll);
    // window && window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window && window.removeEventListener('scroll', handleScroll);
      window && window.removeEventListener('resize', handleScroll);
      // window && window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [hasMore, currentList]);

  return (
    <Center>
      <Container>
        {currentList.map(({ node }, i) => (
          <ProjectPost i={i} {...node} />
        ))}
      </Container>
    </Center>
  );
};

ProjectList.propTypes = {
  items: PropTypes.array.isRequired,
  filterProjects: PropTypes.string,
};

export default ProjectList;
