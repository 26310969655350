import nanostyled from 'nanostyled';

// export const Container = nanostyled('section', {
//   base: 'w-full flex flex-col md:flex-row md:items-end',
// });

export const Wrapper = nanostyled('div', {
  base: 'mx-0 xs:mx-6 sm:mx-16 md:mx-16 flex flex-wrap',
});

export const Center = nanostyled('div', {
  base: 'mx-auto max-w-8xl p-no-space -mt-8',
});

export const Container = nanostyled('section', {
  base: 'flex flex-wrap mx-4 xs:mx-10 mb-4',
});

export const StyledButton = nanostyled('button', {
  base:
    'text-sm sm:text-base font-extrabold tracking-wide uppercase mx-4 mb-4 pb-1',
  active: '',
});

