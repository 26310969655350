import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import ProjectFilter from 'components/project-filter';

const ProjectFilterContainer = ({ items }) => (
  <Consumer>
    {({ filterProjects, changeFilterProjects }) => (
      <ProjectFilter
        items={items}
        filterProjects={filterProjects}
        changeFilterProjects={changeFilterProjects}
      />
    )}
  </Consumer>
);

ProjectFilterContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ProjectFilterContainer;
