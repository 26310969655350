import React from 'react';
import PropTypes from 'prop-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Box from 'components/box';
import { opts } from 'components/page-content';

import {
  ImageLink,
  ImageLinkWrapper,
  TextContainer,
  TextWrapper,
  Text,
  Type,
  StyledImg,
  Overlay,
} from './project-list.css';

const ProjectPost = ({ i, id, category, title, slug, images, text }) => (
  <ImageLinkWrapper
    base="border-solid border-2 border-brand-green w-fullpx -m-px md:my-6 md:mx-10"
    key={id + i}
  >
    <ImageLink flex="flex-col md:flex-row" to={`/projects/${slug}`}>
      {images && (
        <StyledImg
          alt={images[0]?.description}
          image={images[0]?.gatsbyImageData}
          caption={images[0]?.description}
          ar="md:h-full md:w-1/2"
        />
      )}
      <Overlay color="bg-brand-green" />
      <TextContainer width="md:w-1/2">
        <TextWrapper padding="pt-10 pb-10 md:mb-10">
          <Box>
            {/* <span className="flex flex-col items-baseline"> */}
            <Type className="flex flex-col items-baseline">
              {category &&
                category.map(category => <span className="mb-1">{category}</span>)}
            </Type>
            {/* </span> */}
            <Text>{title}</Text>
          </Box>
          {/* <div
                          className="text-xl xs:text-2xl font-light font-micro leading-normal tracking-tight transition duration-200 ease-in-out group-hover:text-white"
                          dangerouslySetInnerHTML={{
                            __html: text.childMarkdownRemark.html,
                          }}

                        /> */}
          <div className="transition duration-200 ease-in-out md:group-hover:text-white">
            {text && renderRichText(text, opts('green'))}
          </div>
        </TextWrapper>
      </TextContainer>
    </ImageLink>
  </ImageLinkWrapper>
);

ProjectPost.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectPost;
