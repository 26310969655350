import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import ProjectList from 'components/project-list';

const ProjectListContainer = ({ items }) => (
  <Consumer>
    {({ filterProjects }) => (
      <ProjectList items={items} filterProjects={filterProjects} />
    )}
  </Consumer>
);

ProjectListContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ProjectListContainer;
