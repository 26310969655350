import nanostyled from 'nanostyled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Center = nanostyled('div', {
  base: 'mx-auto max-w-8xl mt-4',
});
export const Container = nanostyled('section', {
  base: 'flex flex-wrap',
});

export const ImageLinkWrapper = nanostyled('div', {
  base: 'relative',
  width: 'w-full',
});

export const ImageLink = nanostyled(Link, {
  base:
    'w-full flex relative h-full items-center border-solid border-white group cursor-pointer',
  border: '',
  flex: 'flex-col',
});

export const StyledImg = nanostyled(GatsbyImage, {
  base: '',
  ar: 'w-full h-0 aspect-ratio-3/2',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-0 transition duration-200 ease-in-out md:group-hover:opacity-85',
  color: 'bg-brand-green',
});

export const BgImg = nanostyled(GatsbyImage, {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-0 transition duration-200 ease-in-out md:group-hover:opacity-100',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: 'mx-4 xs:mx-10 sm:mx-20',
});

export const TextContainer = nanostyled('div', {
  base: 'inset-0 z-10 max-w-4xl mx-auto',
  padding: '',
  width: '',
});

export const TextWrapper = nanostyled('div', {
  base: 'md:mt-10 flex flex-col justify-start typography green relative flex-grow',
  padding: '',
});

export const Text = nanostyled('h2', {
  base: 'transition duration-200 ease-in-out md:group-hover:text-white !mt-6 !mb-2 !md:mb-6',
});

export const TextBold = nanostyled('h3', {
  base:
    '!text-3xl !font-extrabold transition duration-200 ease-in-out md:group-hover:text-white',
});

export const Type = nanostyled('h4', {
  base:
    'mt-2 mb-0 mr-4 text-brand-green !text-sm transition duration-200 ease-in-out md:group-hover:text-white',
});

export const Date = nanostyled('span', {
  base:
    'mt-2 mb-0 text-xl transition duration-200 ease-in-out md:group-hover:text-white',
});
