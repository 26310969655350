// const { siteTitle, siteDescription } = require('../../site-config');

const buildSeoData = (seoFieldsObject = {}, generateDefaultSeo = {}) => {

  // TODO: replace with config file
  const siteConfigSeo = {
    title: "The Neighborhood Design Center",
    description: "Founded in 1968, the Neighborhood Design Center is a nonprofit organization that facilitates the development of healthy, equitable neighborhoods through community-engaged design and planning services. By providing the tools, expertise, and partnerships necessary to realize neighborhood visions, we support broad participation in the evolution of the built environment.",
  };

  const seoData = {
    ...siteConfigSeo,
    ...generateDefaultSeo,
    ...seoFieldsObject,
  };

  if (seoData.title) {
    seoData.title = `${seoData.title} | The Neighborhood Design Center`;
  }

  return seoData;
};

export default buildSeoData;
